const styles = theme => ({
    golfVacationItem: {
        display: 'flex',
        padding: 6,
        marginBottom: 6,
        backgroundColor: '#dde0dc',
        borderRadius: 10,
        cursor: 'pointer'
    },
    dropCardSection: {
        flexGrow: 1,
        minWidth: 150
    },
    golfVacationDeleteIcon: {
        padding: '0 !important',
        marginRight: '5px !important'
    },
    golfVacationIcon: {
        padding: '0 !important',
        marginRight: '12px !important'
    },
    golfVacationItemSection: {
        backgroundColor: '#d5e2ed',
        fontFamily: 'Hoefler Text, Georgia, Times New Roman, serif',
        fontSize: '1em',
        letterSpacing: '.2em',
        lineHeight: '3em',
        fontWeight: 700,
        textAlign: 'center',
        textTransform: 'uppercase',
        marginBottom: 20,
    },
    dateRangeContainer: {
        display: 'inline-flex',
        flexDirection: 'column'
    },
    dateRangeInput: {
        minWidth: 300,
        position: 'relative',
        top: '-23px',
        left: 0,
        height: 56,
        borderColor: 'rgba(0,0,0,0.23)',
        '& div': {
            borderRadius: 4,
            borderColor: 'rgba(0,0,0,0.23)',
        }
    },
    dateRangeInputError: {
        '& div': {
            borderColor: theme.palette.red
        }
    },
    dateRangeLabel: {
        width: 'max-content',
        backgroundColor: '#ffffff',
        color: 'rgba(0, 0, 0, 0.6)',
        fontSize: '1rem',
        lineHeight: '1.4375em',
        position: 'relative',
        display: 'block',
        transformOrigin: 'top left',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        left: 0,
        top: 0,
        transform: 'translate(14px, -7px) scale(0.75)',
        zIndex: 1,
    },
    dateRangeLabelError: {
        color: theme.palette.red
    },
    getNameButton: {
        height: 56
    },
    radioGroupLabel: {
        marginRight: 10,
        color: 'rgba(0, 0, 0, 0.62)',
        textTransform: 'uppercase',
        fontWeight: 600,
        fontSize: 15,
        width: 100,
        display: 'inline-block'
    },
    statusAndPriceLabel: {
        fontSize: '14px !important'
    },
    statusAndPriceCheckbox: {
        marginTop: '10px !important'
    },
    statusAndPriceInput: {
        width: 150
    },
    statusAndPriceInputContainer: {
        marginTop: 15
    },
    descriptionFieldsContainer: {
        display: 'flex', 
        flexDirection: 'column !important'
    },
    descriptionField: {
        margin: '0 auto 15px auto !important',
        width: '90%'
    },
    descriptionsContainer: {
        marginBottom: 20
    },
    addVacationItemButton: {
        backgroundColor: '#dde0dc !important',
        borderRadius: '10px !important',
        padding: '12px !important',
        fontWeight: '600 !important'
    },
    addVacationItemIcon: {
        marginLeft: 5
    },
    iconSpan: {
        display: 'flex !important',
        alignItems: 'center !important'
    },
});

export default styles;