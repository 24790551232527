import { useRef } from 'react'
import { compose } from 'redux';
import { connect } from 'react-redux';
import { packagesActions } from "../../../redux";
import { useDrag, useDrop } from 'react-dnd'
import { ItemTypes } from '../../../config/ItemTypes';
import { Card, CardHeader, Avatar } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { useClasses } from '../../../config';
import styles from '../styles';

const CoursePackageCard = (props) => {

    const ref = useRef(null);
    const { id, card, index, moveCard } = props;
    const classes = useClasses(styles);

    const [{ handlerId }, drop] = useDrop({
        accept: ItemTypes.CARD,
        collect(monitor) {
            return {
                handlerId: monitor.getHandlerId(),
            }
        },
        hover(item, monitor) {
            if (!ref.current) {
                return
            }
            const dragIndex = item.index
            const hoverIndex = index
            // Don't replace items with themselves
            if (dragIndex === hoverIndex) {
                return
            }
            // Determine rectangle on screen
            const hoverBoundingRect = ref.current?.getBoundingClientRect()
            // Get vertical middle
            const hoverMiddleY =
                (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2
            // Determine mouse position
            const clientOffset = monitor.getClientOffset()
            // Get pixels to the top
            const hoverClientY = clientOffset.y - hoverBoundingRect.top
            // Only perform the move when the mouse has crossed half of the items height
            // When dragging downwards, only move when the cursor is below 50%
            // When dragging upwards, only move when the cursor is above 50%
            // Dragging downwards
            if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                return
            }
            // Dragging upwards
            if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                return
            }
            // Time to actually perform the action
            moveCard(dragIndex, hoverIndex)
            // Note: we're mutating the monitor item here!
            // Generally it's better to avoid mutations,
            // but it's good here for the sake of performance
            // to avoid expensive index searches.
            item.index = hoverIndex
        },
    })

    const [{ isDragging }, drag] = useDrag({
        type: ItemTypes.CARD,
        item: () => {
            return { id, index }
        },
        collect: (monitor) => ({
            isDragging: monitor.isDragging(),
        }),
    });

    const opacity = isDragging ? 0.5 : 1;
    drag(drop(ref));

    const handleToggleMostImportant = (card) => {
        let toggledFlagValue = card.most_important === 'on' ? 0 : 1;
        props.updateCourseMostImportantFlag({id: card.id, data: {flag: toggledFlagValue}});
        props.updateListItemMostImportantFlag(card.id, toggledFlagValue);
    }

    return (
        <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
            <Card className={classes.card} variant='outlined' onDoubleClick={() => handleToggleMostImportant(card)}>
                <CardHeader
                    avatar={
                        card.most_important === 'on' ?
                            <Avatar aria-label="Most Important" style={{ color: 'yellow', background: '#3f51b5' }}>
                                <StarIcon fontSize={"large"} />
                            </Avatar> : <></>
                    }
                    title={card.title}
                />
            </Card>
        </div>
    )
}


const enhance = compose(
    connect(
        state => ({}),
        dispatch => ({
            updateCourseMostImportantFlag(payload) {
                dispatch(packagesActions.updateCourseMostImportantFlag(payload));
            },
        })
    )
);

export default enhance(CoursePackageCard);