import { useState } from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ordersActions } from '../../../redux';
import { Paper, TextField, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useClasses } from "../../../config";
import styles from './styles';
import { toastr } from "react-redux-toastr";
import { UserEmailAutocomplete } from "../../../components/autocomplete";
import PaymentIcon from '@mui/icons-material/Payment';


const OrderSplit = (props) => {
    const [orderInfo, setOrderInfo] = useState({
        firstName: { value: '', error: '' },
        lastName: { value: '', error: '' },
        email: { value: '', error: '' },
        amount: { value: '', error: '' },
        couponCode: { value: '', error: '' },
        couponAmount: { value: '', error: '' },
    });

    const classes = useClasses(styles);
    const { firstName, lastName, email, amount, couponCode, couponAmount } = orderInfo;
    const { orderId, productId, paymentId, existingAmount, users } = props;

    const handleUserInputChange = (value) => {
        setOrderInfo({
            ...orderInfo,
            firstName: { ...orderInfo.firstName, value: value ? value.billing_firstname : '' },
            lastName: { ...orderInfo.lastName, value: value ? value.billing_lastname : '' },
            email: { ...orderInfo.email, value: value ? value.value : '' }
        })
    }

    const handleInputChange = (e) => {
        const targetName = e.target.getAttribute('name');
        setOrderInfo({
            ...orderInfo,
            [targetName]: { ...orderInfo[targetName], value: e.target.value }
        })
    }

    const handleAmountChange = (value) => {
        if (value > existingAmount) {
            toastr.warning('', 'The amount cannot exceed the existing amount');
        } else {
            setOrderInfo({ ...orderInfo, amount: { ...orderInfo.amount, value: value } });
        }
    }

    const validateRequiredFields = () => {
        const emptyFieldMessage = 'Field must not be empty!';
        let validatedOrderInfo = {
            ...orderInfo,
            firstName: { ...orderInfo.firstName, error: orderInfo.firstName.value ? '' : emptyFieldMessage },
            lastName: { ...orderInfo.lastName, error: orderInfo.lastName.value ? '' : emptyFieldMessage },
            email: { ...orderInfo.email, error: orderInfo.email.value ? '' : emptyFieldMessage },
            amount: { ...orderInfo.amount, error: orderInfo.amount.value ? '' : emptyFieldMessage }
        }
        setOrderInfo(validatedOrderInfo);
        return Object.values(validatedOrderInfo).find(e => e.error !== '');
    }

    const splitOrder = () => {
        let invalidField = validateRequiredFields();
        if (invalidField) {
            toastr.warning('', 'Please fill in all the required fields.');
        } else {
            let formValues = {
                billing_firstname: orderInfo.firstName.value,
                billing_lastname: orderInfo.lastName.value,
                email: orderInfo.email.value,
                amount: orderInfo.amount.value,
                cuppon_code: orderInfo.couponCode.value,
                cuppon_amount: orderInfo.couponAmount.value,
            };
            let data = Object.fromEntries(Object.entries(formValues).filter(([, v]) => v !== ''));
            let payload = {
                data: data,
                orderId: orderId,
                productId: productId,
                paymentId: paymentId,
                oldAmount: existingAmount
            }
            props.splitOrder(payload);
        }
    }

    let remainingAmount = orderInfo.amount.value ? existingAmount - orderInfo.amount.value : existingAmount;

    return (
        <Paper>
            <div className={classes.paperContent}>
                <Grid container>
                    <Grid size={6}>
                        <UserEmailAutocomplete
                            handleUserInputChange={handleUserInputChange}
                            users={users}
                        />
                    </Grid>
                    <Grid size={6} className={classes.existingAmountContainer}>
                        <PaymentIcon className={classes.paymentIcon} />
                        {
                            remainingAmount == 0 ?
                                <div>Current order WILL BE DELETED and another one will be created for the selected user</div>
                                : <div>Current order amount will be changed to: {remainingAmount}</div>
                        }

                    </Grid>
                </Grid>
                <Grid container spacing={4} className={classes.fieldsContainer}>
                    <Grid size={4}>
                        <TextField
                            label='First Name'
                            name='firstName'
                            fullWidth
                            required
                            value={firstName.value}
                            onChange={handleInputChange}
                            error={firstName.error ? true : false}
                            helperText={firstName.error}
                        />
                    </Grid>
                    <Grid size={4}>
                        <TextField
                            label='Last Name'
                            name='lastName'
                            fullWidth
                            required
                            value={lastName.value}
                            onChange={handleInputChange}
                            error={lastName.error ? true : false}
                            helperText={lastName.error}
                        />
                    </Grid>
                    <Grid size={4}>
                        <TextField
                            label='Email'
                            name='email'
                            fullWidth
                            required
                            value={email.value}
                            onChange={handleInputChange}
                            error={email.error ? true : false}
                            helperText={email.error}
                        />
                    </Grid>
                    <Grid size={4}>
                        <TextField
                            label='Amount'
                            name='amount'
                            type='number'
                            fullWidth
                            required
                            value={amount.value}
                            onChange={(e) => handleAmountChange(e.target.value)}
                            error={amount.error ? true : false}
                            helperText={amount.error}
                        />
                    </Grid>
                    <Grid size={4}>
                        <TextField
                            label='Coupon Code'
                            name='couponCode'
                            fullWidth
                            value={couponCode.value}
                            onChange={handleInputChange}
                        />
                    </Grid>
                    <Grid size={4}>
                        <TextField
                            label='Coupon Amount'
                            name='couponAmount'
                            fullWidth
                            value={couponAmount.value}
                            onChange={handleInputChange}
                        />
                    </Grid>
                </Grid>
                <div className={classes.paperButtonContainer}>
                    <Button
                        variant='contained'
                        className={classes.paperButton}
                        onClick={splitOrder}
                    >
                        Split Order
                    </Button>
                </div>
            </div>
        </Paper>
    );
}

const enhance = compose(
    connect(
        state => ({}),
        dispatch => ({
            splitOrder(payload) {
                dispatch(ordersActions.splitOrder(payload));
            },
        })
    )
);

export default enhance(OrderSplit);