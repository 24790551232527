import { Fragment, useState } from "react";
import { Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { useClasses } from "../../config";
import styles from "./styles";
import CourseManagament from "./courses/CourseManagament";
import LodgingManagement from "./lodgings/LodgingManagement";


const PackageMangement = () => {

    const [selectedPackage, setSelectedPackage] = useState('');
    const classes = useClasses(styles);

    return (
        <Fragment>
            <Grid container className={classes.buttonsSection}>
                <Grid size={3}>
                    <Button onClick={() => setSelectedPackage('COURSES')} variant='contained'>
                        Reorder Package Courses
                    </Button>
                </Grid>
                <Grid size={3}>
                    <Button onClick={() => setSelectedPackage('LODGING')} variant='contained'>
                        Reorder Package Lodging
                    </Button>
                </Grid>
            </Grid>
            {selectedPackage === 'COURSES' &&
                <CourseManagament />
            }
            {selectedPackage === 'LODGING' &&
                <LodgingManagement />
            }

        </Fragment>

    );
}

export default PackageMangement;