import React from 'react';
import { IconButton, Tooltip } from '@mui/material';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import PaymentsHistory from '../PaymentsHistory';
import { useClasses } from '../../config';
import styles from './styles';


const PaymentsHistoryTooltip = (props) => {

    const { id } = props;
    const classes = useClasses(styles);

    return (
        <Tooltip
            placement='left'
            slotProps={{
                popper: {
                    sx: {
                        '& div': {
                            padding: 0,
                            maxWidth: 2000,
                            backgroundColor: 'white',
                            justifyContent: 'flex-end'
                        }
                    }
                }
            }}
            title={
                <div className={classes.paymentsTooltipContent}>
                    <PaymentsHistory id={id} />
                </div>
            }
        >
            <IconButton>
                <LocalAtmIcon sx={{ color: 'black' }} />
            </IconButton>
        </Tooltip>
    );
}

export default PaymentsHistoryTooltip;