import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ordersActions } from '../../../redux';
import styles from './styles';
import { useParams } from 'react-router-dom';
import { Card, CardContent, Typography, Button } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { useClasses } from '../../../config';
import moment from 'moment';
import OrderSplit from './OrderSplit';
import OrderMove from './OrderMove';


const OrderInformation = (props) => {

    const [display, setDisplay] = useState('');
    const { id } = useParams();
    const classes = useClasses(styles);
    const { orderInfo } = props;
    const { product, payment, billing, users } = orderInfo;

    useEffect(() => {
        props.getOrderById({ id });
    }, []);

    useEffect(() => {
        setDisplay('');
    }, [id]);

    const handleDisplayChange = (value) => {
        if (value === display) {
            setDisplay('');
        } else {
            setDisplay(value);
        }
    }

    return (
        <div>
            <div className={classes.sectionTitle}>Order information</div>
            <Grid container spacing={4} className={classes.orderInfoContainer}>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Card className={classes.orderInfoCard}>
                        <Typography className={classes.orderInfoCardTitle}>Product information</Typography>
                        <CardContent>
                            <div>{product.label}</div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Card className={classes.orderInfoCard}>
                        <Typography className={classes.orderInfoCardTitle}>Billing information</Typography>
                        <CardContent>
                            <div><strong>First name:</strong> {billing.first_name}</div>
                            <div><strong>Last name:</strong> {billing.last_name}</div>
                            <div><strong>Company:</strong> {billing.company}</div>
                            <div><strong>Address:</strong> {billing.address}</div>
                            <div><strong>Suite / Apt:</strong> {billing.suite}</div>
                            <div><strong>City:</strong> {billing.city}</div>
                            <div><strong>Zip:</strong> {billing.zip}</div>
                            <div><strong>Phone:</strong> {billing.phone}</div>
                            <div><strong>State:</strong> {billing.state}</div>
                            <div><strong>Country:</strong> {billing.country}</div>
                            <div><strong>Email:</strong> {billing.email}</div>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid size={{ xs: 12, md: 4 }}>
                    <Card className={classes.orderInfoCard}>
                        <Typography className={classes.orderInfoCardTitle}>Payments information</Typography>
                        <CardContent>
                            <div><strong>User:</strong> {payment.username}</div>
                            <div><strong>Email:</strong> {payment.email}</div>
                            <div><strong>Amount:</strong> {payment.amount}</div>
                            <div><strong>Transaction ID:</strong> {payment.transaction_id}</div>
                            <div><strong>Date:</strong> {moment(payment.date).format('MMMM Do YYYY, h:mm:ss a')}</div>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <div className={classes.buttonsContainer}>
                <Button
                    variant='contained'
                    className={classes.button}
                    onClick={() => handleDisplayChange('split')}
                >
                    Split
                </Button>
                <Button
                    variant='contained'
                    className={classes.button}
                    onClick={() => handleDisplayChange('move')}
                >
                    Move to another quote
                </Button>
            </div>
            <div className={classes.splitAndMoveContainer}>
                {
                    display === 'split' &&
                    <Fragment>
                        <div className={classes.sectionTitle}>Split Order</div>
                        <OrderSplit
                            orderId={id}
                            productId={product.value}
                            paymentId={payment.id}
                            existingAmount={payment.amount}
                            users={users}
                        />
                    </Fragment>
                }
                {
                    display === 'move' &&
                    <Fragment>
                        <div className={classes.sectionTitle}>Move Order</div>
                        <OrderMove
                            orderId={id}
                            paymentId={payment.id}
                            existingAmount={payment.amount}
                            setDisplay={setDisplay}
                        />
                    </Fragment>
                }
            </div>
        </div>
    );
}

const enhance = compose(
    connect(
        state => ({
            orderInfo: state.orders.orderInfo,
        }),
        dispatch => ({
            getOrderById(payload) {
                dispatch(ordersActions.getOrderById(payload));
            },
        })
    )
);

export default enhance(OrderInformation);