import React, { useMemo, useEffect, Fragment, useState } from 'react';
import { Tooltip, IconButton, Typography } from '@mui/material';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import EmailIcon from '@mui/icons-material/Email';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { ConfirmDialog, PaymentHistoryDialog } from '../../components/dialog';
import { useClasses } from '../../config';
import styles from './styles';
import PriceDisplay from '../../components/PriceDisplay';


const DueQuotes = (props) => {

    const [selectedQuoteId, setSelectedQuoteId] = useState(0);
    const [showQuotePayments, setShowQuotePayments] = useState(false);
    const [selectedQuoteIdForDeletion, setSelectedQuoteIdForDeletion] = useState(0);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [showHiddenDialog, setShowHiddenDialog] = useState(false);
    const [selectedQuoteIdToHide, setSelectedQuoteIdToHide] = useState(0);

    const navigate = useNavigate();
    const classes = useClasses(styles);

    useEffect(() => {
        props.getDueQuotes();
    }, []);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'title',
                header: 'Title',
                size: 200,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'date_of_arrival',
                header: 'Date of arrival',
                size: 100,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'days_left',
                header: 'Days left',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'nr_golfers',
                header: 'No. of golfers',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'amount_paid',
                header: 'Paid Amount',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => (
                    <PriceDisplay amount={cell.getValue()} />
                )
            },
            {
                accessorKey: 'total_price',
                header: 'Total to be paid',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => (
                    <PriceDisplay amount={cell.getValue()} />
                )
            },
            {
                accessorKey: 'total_per',
                header: 'Per golfer',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => (
                    <PriceDisplay amount={cell.getValue()} />
                )
            },
        ],
        [],
    );

    const openPaymentHistoryDialog = (id) => {
        setSelectedQuoteId(id);
        setShowQuotePayments(true)
    }

    const openDeleteDialog = (id) => {
        setSelectedQuoteIdForDeletion(id);
        setShowDeleteDialog(true);
    }

    const openHiddenDialog = (id) => {
        setSelectedQuoteIdToHide(id);
        setShowHiddenDialog(true);
    }

    const performDeleteAction = () => {
        props.deleteQuote({ id: selectedQuoteIdForDeletion, section: 'DUE' });
        setShowDeleteDialog(false);
    }

    const performHideAction = () => {
        props.hideQuote({ id: selectedQuoteIdToHide });
        setShowHiddenDialog(false);
    }

    const table = useMaterialReactTable({
        columns,
        data: props.dueQuotesList,
        enableSorting: false,
        enableColumnActions: false,
        defaultColumn: { maxSize: 400, size: 70 },
        muiPaginationProps: {
            rowsPerPageOptions: [10, 15, 100]
        },
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row, table }) => (
            <div className={`actionButtons ${classes.actionsColumn}`}>
                <Tooltip title='Edit'>
                    <IconButton
                        onClick={() => { navigate(`/golf-vacation/${row.original.id}`) }}
                    >
                        <EditIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Delete'>
                    <IconButton
                        onClick={() => { openDeleteDialog(row.original.id) }}
                    >
                        <DeleteIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='View Payment Information'>
                    <IconButton
                        onClick={() => { openPaymentHistoryDialog(row.original.id) }}
                    >
                        <LocalAtmIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title='Hide Quote'>
                    <IconButton
                        onClick={() => { openHiddenDialog(row.original.id) }}
                    >
                        <VisibilityOffIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title={row.original.payment_email === 0 ? 'Payment reminder email not sent' : 'Payment reminder email sent'}>
                    <IconButton
                        onClick={() => props.sendPaymentReminderEmail({ id: row.original.id })}
                    >
                        <EmailIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
            </div>
        ),
        renderTopToolbar: () => (
            <div className={classes.tableTitle}>
                <Typography variant='h6'>Due Quotes</Typography>
            </div>
        )
    });

    return (
        <Fragment>
            <div className={`dueQuotesSection mainSection ${classes.dueQuotesTable}`}>
                <MaterialReactTable table={table} />
            </div>
            {showQuotePayments &&
                <PaymentHistoryDialog
                    id={selectedQuoteId}
                    open={showQuotePayments}
                    cancelAction={() => { setShowQuotePayments(false) }}
                />
            }
            <ConfirmDialog
                open={showDeleteDialog}
                text='Are you sure you want to delete this quote?'
                okText='Yes'
                okAction={performDeleteAction}
                cancelText='No'
                cancelAction={() => { setShowDeleteDialog(false) }}
            />
            <ConfirmDialog
                open={showHiddenDialog}
                text='Are you sure you want to hide this quote?'
                okText='Yes'
                okAction={performHideAction}
                cancelText='No'
                cancelAction={() => { setShowHiddenDialog(false) }}
            />
        </Fragment>
    );
}

export default DueQuotes;