import { Typography, Card, List, ListItemButton, ListItemText, Collapse, Table, TableHead, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';
import Grid from '@mui/material/Grid2';
import PaidIcon from '@mui/icons-material/Paid';
import { getQuotePayments } from '../services/quotes';
import React, { useState, useEffect, useMemo, Fragment } from 'react';
import { formatDate, useClasses } from '../config';
import styles from './styles';
import { toastr } from 'react-redux-toastr';

const PaymentsHistory = (props) => {

    const [data, setData] = useState([]);
    const classes = useClasses(styles);
    const { id } = props;

    useEffect(() => {
        getQuotePaymentHistory();
    }, [id]);

    const getQuotePaymentHistory = () => {
        getQuotePayments({ id })
            .then(response => {
                if (response.status === 200) {
                    let parsedData = Object.values(response.data).reduce((acc, item) => {
                        item.totalLeft = (item.sum_total - item.total_paid) < 0 ? 0 : item.sum_total - item.total_paid;
                        item.payments = item.payments.reduce((paymentAcc, payment) => {
                            let key = payment.email;
                            if (!paymentAcc[key]) {
                                paymentAcc[key] = { name: payment.first_name.toLowerCase() + ' ' + payment.last_name.toLowerCase(), payments: [], total: 0 };
                            }
                            paymentAcc[key].total += payment.amount_paid;
                            paymentAcc[key].payments.push(payment);
                            return paymentAcc;
                        }, []);
                        acc.push(item);
                        return acc;
                    }, []);
                    setData(parsedData)
                }
                else {
                    toastr.error('', 'Error while retrieving payment history');
                }
            });
    };

    const columns = useMemo(
        () => ['Title', 'Total price', 'Total paid', 'Unpaid', 'Golfers', 'Price per', 'Price'], []
    );
    
    return (
        <div>
            <div>
                <TableContainer>
                    <Table className={classes.paymentsTable}>
                        <TableHead>
                            <TableRow>
                                {columns.map(column =>
                                    <TableCell sx={{ fontWeight: 900 }} key={column}
                                        align='center'>{column}</TableCell>)}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data ?
                                Object.values(data).map((item, index) => {
                                    return <TableRow key={index}>
                                        <TableCell align='center'>{item.title} </TableCell>
                                        <TableCell align='center'>{item.sum_total.toFixed(2)} </TableCell>
                                        <TableCell align='center'>{item.total_paid.toFixed(2)}</TableCell>
                                        <TableCell align='center'>{item.totalLeft.toFixed(2)}</TableCell>
                                        <TableCell align='center'>{item.total_golfers}</TableCell>
                                        <TableCell align='center'>{item.price_per}</TableCell>
                                        <TableCell align='center'>{item.total_price}</TableCell>
                                    </TableRow>
                                }) : null
                            }
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>
            <Grid container spacing={4} className={classes.paymentsContainer}>
                {data.map((item, index) => {
                    return <Grid key={index}>
                        <Card className={classes.paymentCard}>
                            <Typography className={classes.paymentCardTitle}>{item.title}</Typography>
                            <List className={classes.paymentsList}>
                                {Object.keys(item.payments).map((email, index) => {
                                    return <Fragment key={index}>
                                        <ListItemButton className={classes.accountPayment}>
                                            <ListItemText className={classes.accountPaymentText} primary={`${item.payments[email].name} - ${item.payments[email].total} ${item.currency}`} />
                                        </ListItemButton>
                                        <Collapse in={true}>
                                            <List component="div" disablePadding>
                                                {item.payments[email].payments.map((payment, index) => {
                                                    return <ListItemButton key={index} className={classes.individualPayment}>
                                                        <PaidIcon className={classes.paymentIcon} />
                                                        <ListItemText primary={`${formatDate(payment.payment_date)} - ${payment.amount_paid}`} />
                                                    </ListItemButton>
                                                })}
                                            </List>
                                        </Collapse>
                                    </Fragment>
                                })}
                            </List>
                        </Card>
                    </Grid>
                })}
            </Grid>
        </div>
    );
}

export default PaymentsHistory;