import { useState } from "react";
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ordersActions } from '../../../redux';
import { Checkbox, FormControlLabel, Paper, TextField, Button } from "@mui/material";
import Grid from '@mui/material/Grid2';
import { ProductAutocomplete } from "../../../components/autocomplete";
import { useClasses } from "../../../config";
import styles from './styles';
import { toastr } from "react-redux-toastr";
import { ConfirmDialog } from "../../../components/dialog";


const OrderMove = (props) => {
    const [productValue, setProductValue] = useState(null);
    const [moveEntireAmount, setMoveEntireAmount] = useState(true);
    const [amount, setAmount] = useState('');
    const [invalidAmount, setInvalidAmount] = useState('');
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const classes = useClasses(styles);
    const { existingAmount, paymentId, orderId, setDisplay } = props;

    const handleAmountChange = (value) => {
        if (value > existingAmount) {
            toastr.warning('', 'The amount cannot exceed the existing amount');
        } else {
            setAmount(value);
        }
    }

    const validateFields = () => {
        if (!moveEntireAmount && amount === '') {
            setInvalidAmount('Field must not be empty!');
            return;
        }
        if (!productValue) {
            toastr.warning('', 'Please choose a golf vacation item!');
            return;
        }
        setShowConfirmDialog(true);
    }

    const moveOrder = () => {
        setShowConfirmDialog(false);
        let transferAmount = moveEntireAmount ? '' : amount;
        let data = { paymentId: paymentId, entireAmount: moveEntireAmount, amount: transferAmount };
        let payload = {
            data: data,
            orderId: orderId,
            toVacationItemId: productValue.value
        }

        props.moveOrder(payload);
        setDisplay('');
        //TODO: if the new endpoint is not updated, make another call to retrieve the details for the order
    }

    return (
        <Paper>
            <div className={classes.paperContent}>
                <Grid container className={classes.moveOrderFileds}>
                    <Grid size={{ xs: 12, md: 4 }}>
                        <ProductAutocomplete
                            placeholder='Golf vacation item'
                            value={productValue}
                            handleValueChange={setProductValue}
                        />
                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }} className={classes.existingAmountContainer}>
                        <FormControlLabel control={
                            <Checkbox
                                checked={moveEntireAmount}
                                onChange={(e) => { setMoveEntireAmount(e.target.checked) }}
                            />
                        } label='Entire Amount' />

                    </Grid>
                    <Grid size={{ xs: 12, md: 4 }}>
                        {!moveEntireAmount &&
                            <TextField
                                label='Amount'
                                type='number'
                                fullWidth
                                required
                                value={amount}
                                onChange={(e) => handleAmountChange(e.target.value)}
                                error={invalidAmount ? true : false}
                                helperText={invalidAmount}
                                size='small'
                            />
                        }
                    </Grid>
                </Grid>
                <div className={classes.paperButtonContainer}>
                    <Button
                        variant='contained'
                        className={classes.paperButton}
                        onClick={validateFields}
                    >
                        Move Order
                    </Button>
                </div>
            </div>
            {
                productValue &&
                <ConfirmDialog
                    open={showConfirmDialog}
                    text={`Are you sure you want to move this payment to ${productValue.label}?`}
                    okText='Yes'
                    okAction={moveOrder}
                    cancelText='No'
                    cancelAction={() => { setShowConfirmDialog(false) }}
                />
            }
        </Paper>
    );
}

const enhance = compose(
    connect(
        state => ({}),
        dispatch => ({
            moveOrder(payload) {
                dispatch(ordersActions.moveOrder(payload));
            },
        })
    )
);

export default enhance(OrderMove);