import React, { Fragment, useEffect, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { TextField, InputAdornment, IconButton, Button, Switch } from '@mui/material';
import Grid from '@mui/material/Grid2';
import RichTextEditor from '../../../components/editor';
import RefreshIcon from '@mui/icons-material/Refresh';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import { capitalizeText, formatAndCapitalizeText, useBlockNavigation, Constants } from '../../../config';
import { slugify } from '../../../services/quotes';
import { toastr } from 'react-redux-toastr';
import { useLocation } from 'react-router-dom';
import { CustomizeEmailDialog } from '../../../components/dialog';
import { quotesActions } from '../../../redux';

const initialSuggestionContent = '<p><strong>Booking Notes:</strong></p><ul><li><p>The following proposal(s) are quotes only, space is not being held at the hotel or golf courses</p></li><li><p>Lodging, golf courses, and tee times are all suggestions, therefore, can be revised to best fit your travel plans, ideal golf schedule, and needs, based on availability</p></li><li><p>Package prices are subject to change</p></li></ul></br>';


const QuoteInfo = (props) => {

    const [showProposalDialog, setShowProposalDialog] = useState(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState(false);
    const [shouldBlockNavigation, setShouldBlockNavigation] = useState(false);

    const { id, classes, quoteInfo, setQuoteInfo, updateQuoteInfo, emailInfo, sendGenericEmail, sendConfirmationEmail, items } = props;
    const location = useLocation();
    useBlockNavigation(shouldBlockNavigation);

    useEffect(() => {
        if (!id) {
            setupNonExistingQuote();
        }
    }, [id, location.state]);

    // Show confirmation/simple alert when the user wants to navigate/change the tab and the quote is under review
    useEffect(() => {
        if (quoteInfo.quoteUnderReview) {
            setShouldBlockNavigation(true)
        } else {
            setShouldBlockNavigation(false);
        }

        const handleVisibilityChange = () => {
            if (quoteInfo.quoteUnderReview && document.hidden) {
                alert(Constants.UNDER_REVIEW_CONFIRM_NAVIGATION);
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);

        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [quoteInfo.quoteUnderReview]);

    const setupNonExistingQuote = () => {
        const leadInfo = location.state;
        if (leadInfo) {
            const name = leadInfo.name + ' Golf Vacation';
            setQuoteInfo({
                info: {
                    title: name, seoTitle: name, email: leadInfo.email, quoteUnderReview: 0,
                    seoLink: '', suggestionContent: initialSuggestionContent,
                }
            });
            updateSlug(name);
        } else {
            resetState();
        }
    }

    const resetState = () => {
        setQuoteInfo({
            info: {
                title: '', email: '', seoLink: '', seoTitle: '', quoteUnderReview: 0,
                suggestionContent: initialSuggestionContent, initialContent: initialSuggestionContent
            }
        });
    }

    const handleInputChange = (e) => {
        const targetName = e.target.getAttribute('name');
        updateQuoteInfo({ target: targetName, value: e.target.value });
    }

    const handleTitleChange = (e) => {
        let value = capitalizeText(e.target.value);
        setQuoteInfo({ info: { ...quoteInfo, title: value, seoTitle: value } });
        if (!id) {
            updateSlug(value);
        }
    }

    const updateSlug = (value) => {
        slugify({ text: value })
            .then(response => {
                if (response.status === 200) {
                    updateQuoteInfo({ target: 'seoLink', value: response.data.slug });
                    toastr.success('', 'Slug updated');
                }
                else {
                    toastr.error('', 'Something went wrong');
                }
            });
    }

    const handleSeoTitleChange = (e) => {
        updateQuoteInfo({ target: 'seoTitle', value: capitalizeText(e.target.value) });
    }

    const handleFormatTitle = (value) => {
        let formattedValue = formatAndCapitalizeText(value);
        setQuoteInfo({ info: { ...quoteInfo, title: formattedValue, seoTitle: formattedValue } });
    }

    const handleSuggestionContentChange = (value) => {
        updateQuoteInfo({ target: 'suggestionContent', value: value });
    }

    const openProposalDialog = () => {
        if (quoteInfo.quoteUnderReview) {
            const userConfirmed = window.confirm(Constants.UNDER_REVIEW_CONFIRM_ACTION_MESSAGE);
            if (!userConfirmed) return;
        }

        let publishedVacationItems = items.filter(item => item.published == 1);
        if (publishedVacationItems.length > 0) {
            setShowProposalDialog(true);
        } else {
            toastr.warning('', 'At least one published vacation item is required!')
        }
    }

    const openConfirmationDialog = () => {
        if (quoteInfo.quoteUnderReview) {
            const userConfirmed = window.confirm(Constants.UNDER_REVIEW_CONFIRM_ACTION_MESSAGE);
            if (!userConfirmed) return;
        }

        setShowConfirmationDialog(true)
    }

    return (
        <Fragment>
            {
                quoteInfo &&
                <Fragment>
                    <Grid container rowSpacing={3}>
                        <Grid size={6} className={classes.titleAndEmailTextfields}>
                            <TextField
                                label='Title'
                                name='title'
                                value={quoteInfo.title}
                                onChange={handleTitleChange}
                                required
                                fullWidth
                                className={classes.generalInfoInput}
                                error={quoteInfo.title === ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => { handleFormatTitle(quoteInfo.title) }}>
                                                <TextFieldsIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid size={6} className={classes.titleAndEmailTextfields}>
                            <TextField
                                label='Email'
                                name='email'
                                value={quoteInfo.email}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                error={quoteInfo.email === ''}
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                label='Seo Slug'
                                name='seoLink'
                                value={quoteInfo.seoLink}
                                onChange={handleInputChange}
                                required
                                fullWidth
                                className={classes.generalInfoInput}
                                error={quoteInfo.seoLink === ''}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position='end'>
                                            <IconButton onClick={() => { updateSlug(quoteInfo.title) }}>
                                                <RefreshIcon sx={{ color: 'black' }} />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }}
                            />
                        </Grid>
                        <Grid size={6}>
                            <TextField
                                label='Seo Title'
                                name='seoTitle'
                                value={quoteInfo.seoTitle}
                                onChange={handleSeoTitleChange}
                                fullWidth
                            />
                        </Grid>
                    </Grid>
                    <RichTextEditor
                        initialContent={quoteInfo.initialContent}
                        content={quoteInfo.suggestionContent}
                        handleContentChange={handleSuggestionContentChange}
                        height={280}
                    />
                </Fragment>
            }
            <div className={classes.reviewEmailSection}>
                {
                    id &&
                    <Fragment>
                        <Fragment>
                            <Switch
                                checked={quoteInfo.quoteUnderReview ? true : false}
                                onChange={(e) => props.setQuoteUnderReviewFlag({ id: id, flag: e.target.checked ? 1 : 0 })}
                            />
                            <span className={classes.reviewText} >Under Review</span>
                        </Fragment>
                        <div className={classes.emailSection}>
                            {
                                emailInfo && emailInfo.confirmed == 0 &&
                                <Button className='proposalEmailButtom' variant='contained' color='secondary' onClick={openProposalDialog}>
                                    Send Proposal email
                                </Button>
                            }
                            <Button className='confirmationEmailButtom' variant='contained' onClick={openConfirmationDialog}>
                                Send Confirmation email
                            </Button>
                        </div>
                    </Fragment>
                }
            </div>
            {
                showProposalDialog &&
                <CustomizeEmailDialog
                    open={showProposalDialog}
                    type={'proposal'}
                    title={'Send prosposal email'}
                    email={emailInfo.email}
                    name={emailInfo.name}
                    link={emailInfo.link}
                    sendAction={sendGenericEmail}
                    cancelAction={() => setShowProposalDialog(false)}
                />
            }
            {
                showConfirmationDialog &&
                <CustomizeEmailDialog
                    open={showConfirmationDialog}
                    type={'confirmation'}
                    title={'Send confirmation email'}
                    email={emailInfo.email}
                    name={emailInfo.name}
                    link={emailInfo.link}
                    sendAction={(payload) => sendConfirmationEmail({ id: id, body: payload })}
                    cancelAction={() => setShowConfirmationDialog(false)}
                />
            }
        </Fragment>

    );
}

const enhance = compose(
    connect(
        state => ({
            emailInfo: state.quotes.emailInfo,
        }),
        dispatch => ({
            sendGenericEmail(payload) {
                dispatch(quotesActions.sendGenericEmail(payload));
            },
            sendConfirmationEmail(payload) {
                dispatch(quotesActions.sendConfirmationEmail(payload));
            },
            setQuoteInfo(payload) {
                dispatch(quotesActions.setQuoteInfo(payload));
            },
            updateQuoteInfo(payload) {
                dispatch(quotesActions.updateQuoteInfo(payload));
            },
            setQuoteUnderReviewFlag(payload) {
                dispatch(quotesActions.setQuoteUnderReviewFlag(payload));
            },
        })
    )
);

export default enhance(QuoteInfo);
