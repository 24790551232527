import React, { Fragment, useState } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { ordersActions } from '../../../redux';
import styles from './styles';
import { TextField, Button, Paper, InputLabel } from '@mui/material';
import Grid from '@mui/material/Grid2';
import { ProductAutocomplete, UserEmailAutocomplete } from '../../../components/autocomplete';
import { toastr } from 'react-redux-toastr';
import { useClasses } from '../../../config';

const CreateOrder = (props) => {

    const [productValue, setProductValue] = useState(null);
    const [orderInfo, setOrderInfo] = useState({
        firstName: { value: '', error: '' },
        lastName: { value: '', error: '' },
        email: { value: '', error: '' },
        amount: { value: '', error: '' },
        couponCode: { value: '', error: '' },
        couponAmount: { value: '', error: '' },
    });

    const classes = useClasses(styles);
    const { firstName, lastName, email, amount, couponCode, couponAmount } = orderInfo;

    const handleUserInputChange = (value) => {
        setOrderInfo({
            ...orderInfo,
            firstName: { ...orderInfo.firstName, value: value ? value.billing_firstname : '' },
            lastName: { ...orderInfo.lastName, value: value ? value.billing_lastname : '' },
            email: { ...orderInfo.email, value: value ? value.value : '' }
        })
    }

    const handleInputChange = (e) => {
        const targetName = e.target.getAttribute('name');
        setOrderInfo({
            ...orderInfo,
            [targetName]: { ...orderInfo[targetName], value: e.target.value }
        })
    }

    const handleProductChange = (value) => {
        setProductValue(value);
        props.getUsersByProduct({ id: value.value });
    }

    const validateRequiredFields = () => {
        const emptyFieldMessage = 'Field must not be empty!';
        setOrderInfo({
            ...orderInfo,
            firstName: { ...orderInfo.firstName, error: orderInfo.firstName.value ? '' : emptyFieldMessage },
            lastName: { ...orderInfo.lastName, error: orderInfo.lastName.value ? '' : emptyFieldMessage },
            email: { ...orderInfo.email, error: orderInfo.email.value ? '' : emptyFieldMessage },
            amount: { ...orderInfo.amount, error: orderInfo.amount.value ? '' : emptyFieldMessage }
        })
    }

    const save = () => {
        validateRequiredFields();
        let invalidField = Object.values(orderInfo).find(e => e.error !== '');
        if (invalidField || productValue === '') {
            toastr.warning('', 'Please fill in all the required fields.');
        } else {
            let formValues = {
                users: [],
                billing_firstname: orderInfo.firstName.value,
                billing_lastname: orderInfo.lastName.value,
                email: orderInfo.email.value,
                amount: orderInfo.amount.value,
                cuppon_code: orderInfo.couponCode.value,
                cuppon_amount: orderInfo.couponAmount.value,
                product: productValue
            };
            let payload = Object.fromEntries(Object.entries(formValues).filter(([, v]) => v !== ''));
            props.saveOrder(payload);
        }
    }

    return (
        <Fragment>
            <div className={classes.sectionTitle}>Create order</div>
            <Paper elevation={5} square={false}>
                <div className={classes.paperContent}>
                    <Grid container className={classes.autoCompleteContainer}>
                        <Grid size={6}>
                            <div className={classes.autocomplete}>
                                <InputLabel>Product</InputLabel>
                                <ProductAutocomplete
                                    value={productValue}
                                    handleValueChange={handleProductChange}
                                />
                            </div>
                        </Grid>
                        <Grid size={6}>
                            <div className={classes.autocomplete}>
                            <InputLabel>Search user by email</InputLabel>
                                <UserEmailAutocomplete
                                    users={props.orderUsers}
                                    handleUserInputChange={handleUserInputChange}
                                />
                            </div>
                        </Grid>
                    </Grid>
                    <Grid container spacing={4} className={classes.fieldsContainer}>
                        <Grid size={4}>
                            <TextField
                                label='First Name'
                                name='firstName'
                                fullWidth
                                required
                                value={firstName.value}
                                onChange={handleInputChange}
                                error={firstName.error ? true : false}
                                helperText={firstName.error}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TextField
                                label='Last Name'
                                name='lastName'
                                fullWidth
                                required
                                value={lastName.value}
                                onChange={handleInputChange}
                                error={lastName.error ? true : false}
                                helperText={lastName.error}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TextField
                                label='Email'
                                name='email'
                                fullWidth
                                required
                                value={email.value}
                                onChange={handleInputChange}
                                error={email.error ? true : false}
                                helperText={email.error}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TextField
                                label='Amount'
                                name='amount'
                                fullWidth
                                required
                                value={amount.value}
                                onChange={handleInputChange}
                                error={amount.error ? true : false}
                                helperText={amount.error}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TextField
                                label='Coupon Code'
                                name='couponCode'
                                fullWidth
                                value={couponCode.value}
                                onChange={handleInputChange}
                            />
                        </Grid>
                        <Grid size={4}>
                            <TextField
                                label='Coupon Amount'
                                name='couponAmount'
                                fullWidth
                                value={couponAmount.value}
                                onChange={handleInputChange}
                            />
                        </Grid>
                    </Grid>
                    <div className={classes.paperButtonContainer}>
                        <Button
                            variant='contained'
                            className={classes.paperButton}
                            onClick={save}
                        >
                            Save Order
                        </Button>
                    </div>
                </div>
            </Paper>
        </Fragment>
    );
}

const enhance = compose(
    connect(
        state => ({
            orderUsers: state.orders.orderUsers,
        }),
        dispatch => ({
            saveOrder(payload) {
                dispatch(ordersActions.saveOrder(payload));
            },
            getUsersByProduct(payload) {
                dispatch(ordersActions.getUsersByProduct(payload));
            }
        })
    )
);


export default enhance(CreateOrder);