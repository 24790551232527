import React, { useEffect } from 'react';
import { useClasses } from '../../config';
import styles from './styles';
import TeeTimeReminderTable from './TeeTimeReminderTable';

const TeeTimeReminder = (props) => {

    const { teeTimeReminders } = props;
    const { primary, secondary, pendingPrimary, pendingSecondary } = teeTimeReminders;
    const classes = useClasses(styles);

    useEffect(() => {
        props.getTeeTimeReminders();
    }, []);

    return (
        <div className='mainSection'>
            <div className={classes.table}>
                <TeeTimeReminderTable
                    data={primary}
                    title='Confirmed'
                />
            </div>
            <div className={classes.table}>
                <TeeTimeReminderTable
                    data={secondary}
                    title='Confirmed Secondary'
                />
            </div>
            <div className={classes.table}>
                <TeeTimeReminderTable
                    data={pendingPrimary}
                    title='Pending'
                />
            </div>
            <div className={classes.table}>
                <TeeTimeReminderTable
                    data={pendingSecondary}
                    title='Pending Secondary'
                />
            </div>
        </div>
    );
}

export default TeeTimeReminder;