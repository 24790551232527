import React, {useMemo} from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { IconButton, Tooltip, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useClasses } from '../../config';
import styles from './styles';

const TeeTimeReminderTable = (props) => {

    const {data, title} = props;
    const navigate = useNavigate();
    const classes = useClasses(styles);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'vacation_title',
                header: 'Title',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'course_title',
                header: 'Course title',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'last_name',
                header: 'Last name',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'course_date',
                header: 'Course date',
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'course_time',
                header: 'Course time',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
            {
                accessorKey: 'nr_days',
                header: 'TBD days',
                size: 50,
                muiTableHeadCellProps: {
                    align: 'center',
                },
                muiTableBodyCellProps: {
                    align: 'center',
                },
            },
        ],
        [],
    );

    const table = useMaterialReactTable({
        columns,
        data,
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row, table }) => (
            <Tooltip title="Edit">
                <IconButton
                    onClick={() => { navigate(`/golf-vacation/${row.original.vacation_id}`) }}
                >
                    <EditIcon sx={{color: 'black'}}/>
                </IconButton>
            </Tooltip>
        ),
        muiTablePaperProps: { elevation: 16 },
        renderTopToolbar: () => (
            <div className={classes.tableTitle}>
                <Typography variant='h6'>{title}</Typography>
            </div>
        )
    });

    return ( 
        <MaterialReactTable table={table}/>
     );
}
 
export default TeeTimeReminderTable;