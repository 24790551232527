import React, { useEffect, useState, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { Button, Tooltip, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { ConfirmDialog } from '../../components/dialog';
import { ProductAutocomplete } from '../../components/autocomplete';
import SearchIcon from '@mui/icons-material/Search';
import SearchOffIcon from '@mui/icons-material/SearchOff';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useClasses } from '../../config';
import styles from './styles';
import PriceDisplay from '../../components/PriceDisplay';


const Orders = (props) => {

    const initialPageSize = parseInt(localStorage.getItem('ordersPageSize')) || 10;
    const initialProductValue = localStorage.getItem('orderSearch') ? JSON.parse(localStorage.getItem('orderSearch')) : null;

    const [showSearchBar, setShowSearchBar] = useState(false);
    const [productValue, setProductValue] = useState(initialProductValue);
    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selectedOrderId, setSelectedOrderId] = useState(0);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: initialPageSize,
    });
    const navigate = useNavigate();
    const classes = useClasses(styles);
    const { ordersList, totalPages } = props;

    useEffect(() => {
        const { pageIndex, pageSize } = pagination;
        const params = { page: pageIndex + 1, perPage: pageSize };
        if (productValue) {
            params.vacationItemId = productValue.value
        }
        props.getOrders(params);
    },
        [
            pagination.pageIndex,
            pagination.pageSize,
            productValue
        ]);

    useEffect(() => {
        localStorage.setItem('ordersPageSize', pagination.pageSize);
    }, [pagination.pageSize]);

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'username',
                header: 'Username',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'email',
                header: 'Email',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'firstname',
                header: 'First Name',
                size: 90,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'lastname',
                header: 'Last Name',
                size: 90,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
            },
            {
                accessorKey: 'title',
                header: 'Title',
                size: 360,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'amount',
                header: 'Total',
                size: 50,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => (
                    <PriceDisplay amount={cell.getValue()} />
                )
            },
            {
                accessorKey: 'paid',
                header: 'Paid',
                size: 50,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
        ],
        [],
    );

    const showConfirmationDialogForDeletion = (id) => {
        setSelectedOrderId(id);
        setShowDeleteDialog(true);
    }

    const deleteOrder = () => {
        props.deleteOrder({ id: selectedOrderId });
        setShowDeleteDialog(false);
    }

    const handleProductChange = (productValue) => {
        localStorage.setItem('orderSearch', JSON.stringify(productValue));
        setProductValue(productValue);
    }

    const toggleSearchBar = () => {
        setShowSearchBar(showSearchBar => !showSearchBar);
    }

    const resetSearch = () => {
        setProductValue(null);
        localStorage.removeItem('orderSearch')
    }

    const table = useMaterialReactTable({
        columns,
        data: ordersList,
        state: { pagination },
        manualPagination: true,
        onPaginationChange: setPagination,
        rowCount: totalPages,
        muiPaginationProps: {
            rowsPerPageOptions: [10, 50, 100, 200]
        },
        autoResetPageIndex: false,
        muiTablePaperProps: { elevation: 12 },
        defaultColumn: { minSize: 30, size: 80, maxSize: 400 },
        enableSorting: false,
        enableColumnActions: false,
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row, table }) => (
            <div className={classes.actionButtons}>
                <Tooltip title="Edit Order">
                    <IconButton
                        onClick={() => { navigate(`/order/view/${row.original.id}`) }}
                    >
                        <EditIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Delete Order">
                    <IconButton
                        onClick={() => { showConfirmationDialogForDeletion(row.original.id) }}
                    >
                        <DeleteIcon sx={{ color: 'black' }} />
                    </IconButton>
                </Tooltip>
            </div>
        ),
        renderTopToolbarCustomActions: () => {
            return showSearchBar || productValue ?
                <div className={classes.searchContainer}>
                    <div className={classes.searchBox}>
                        <ProductAutocomplete
                            value={productValue}
                            handleValueChange={handleProductChange}
                        />
                    </div>
                    <Button
                        variant='contained'
                        className={classes.resetButton}
                        onClick={resetSearch}
                    >
                        <RefreshIcon />
                    </Button>
                </div>
                :
                <div className={classes.tableTitle}>
                    <Typography variant='h6'>Orders</Typography>
                </div>
        },
        renderToolbarInternalActions: () => (
            <div>
                <Tooltip title='Search'>
                    <IconButton onClick={toggleSearchBar} size='large'>
                        {showSearchBar ?
                            <SearchOffIcon color='primary' fontSize='inherit' />
                            :
                            <SearchIcon color='primary' fontSize='inherit' />
                        }
                    </IconButton>
                </Tooltip>
                <Tooltip title='Add a new order'>
                    <IconButton onClick={() => { navigate('/order/create') }} size='large'>
                        <NoteAddIcon color='secondary' fontSize='inherit' />
                    </IconButton>
                </Tooltip>
            </div>
        )
    })

    return (
        <div className={`ordersSection mainSection ${classes.ordersTable}`}>
            <MaterialReactTable table={table} />
            <ConfirmDialog
                open={showDeleteDialog}
                text='Are you sure you want to delete this order?'
                okText='Yes'
                okAction={deleteOrder}
                cancelText='No'
                cancelAction={() => { setShowDeleteDialog(false) }}
            />
        </div>
    );
}

export default Orders;