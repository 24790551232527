const styles = theme => ({
    actionButtons: {
        minWidth: 120,
        display: 'flex',
        justifyContent: 'center',
        margin: 'auto'
    },
    leadsTable: {
        '& table': {
            '& tbody tr td:nth-child(6)': {
                paddingLeft: 0,
                paddingRight: 0,
            },
            '& th': {
                lineHeight: '1.2rem',
                fontWeight: 600,
                '& div': {
                    whiteSpace: 'unset'
                }
            }
        }
    },
    tableTitle: {
        padding: '10px 10px 20px 20px',
        fontWeight: '500'
    }
});

export default styles;