import React, { useState, useEffect, useMemo } from 'react';
import { MaterialReactTable, useMaterialReactTable } from 'material-react-table';
import { useClasses } from '../../config';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Tooltip, IconButton, Typography } from '@mui/material';
import styles from './styles';
import PriceDisplay from '../../components/PriceDisplay';

const InProgressQuotePayments = (props) => {

    const initialPageSize = parseInt(localStorage.getItem('inProgressPaymentsPageSize')) || 10;
    const initialSearchText = localStorage.getItem('inProgressPaymentsSearch') || '';

    const [searchText, setSearchText] = useState(initialSearchText);
    const [pagination, setPagination] = useState({
        pageIndex: 0,
        pageSize: initialPageSize,
    });
    const classes = useClasses(styles);
    const { quotesList, totalRows } = props;

    useEffect(() => {
        const { pageIndex, pageSize } = pagination;
        const params = {
            page: pageIndex + 1, perPage: pageSize, search: searchText ? searchText : ''
        }
        props.getInProgressQuotePayments(params);
    },
        [
            pagination.pageIndex,
            pagination.pageSize,
            searchText
        ]);

    useEffect(() => {
        localStorage.setItem('inProgressPaymentsPageSize', pagination.pageSize);
    }, [pagination.pageSize]);

    const amountPaidSum = useMemo(() => {
        return quotesList.reduce((sum, row) => {
            if (!row.hidden) {
                return sum + (row.amount_paid || 0);
            }
            return sum;
        }, 0);
    }, [quotesList]);

    const totalPriceWithoutMarkupSum = useMemo(() => {
        return quotesList.reduce((sum, row) => {
            if (!row.hidden) {
                return sum + (row.golf_vacation_total_without_markup || 0);
            }
            return sum;
        }, 0);
    }, [quotesList]);

    const totalPriceSum = useMemo(() => {
        return quotesList.reduce((sum, row) => {
            if (!row.hidden) {
                return sum + (row.golf_vacation_total || 0);
            }
            return sum;
        }, 0);
    }, [quotesList]);


    const columns = useMemo(
        () => [
            {
                accessorKey: 'golf_vacation_item_id',
                header: 'Golf Vacation Item Id',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Footer: () => (
                    <Typography variant='body2' align='center' fontWeight='bold'>
                        Total
                    </Typography>
                )
            },
            {
                accessorKey: 'title',
                header: 'Title',
                size: 180,
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'date_of_arrival',
                header: 'Date Of Arrival',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'date_of_departure',
                header: 'Date Of Departure',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' }
            },
            {
                accessorKey: 'amount_paid',
                header: 'Paid amount',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => (
                    <PriceDisplay amount={cell.getValue()} />
                ),
                Footer: () => (
                    <div className={classes.totalBox}>
                        <PriceDisplay amount={amountPaidSum} />
                    </div>
                )
            },
            {
                accessorKey: 'golf_vacation_total_without_markup',
                header: 'Total cost',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => (
                    <PriceDisplay amount={cell.getValue()} />
                ),
                Footer: () => (
                    <div className={classes.totalBox}>
                        <PriceDisplay amount={totalPriceWithoutMarkupSum} />
                    </div>
                )
            },
            {
                accessorKey: 'golf_vacation_total',
                header: 'Total price',
                muiTableHeadCellProps: { align: 'center' },
                muiTableBodyCellProps: { align: 'center' },
                Cell: ({ cell }) => (
                    <PriceDisplay amount={cell.getValue()} />
                ),
                Footer: () => (
                    <div className={classes.totalBox}>
                        <PriceDisplay amount={totalPriceSum} />
                    </div>
                )
            }
        ],
        [amountPaidSum, totalPriceWithoutMarkupSum, totalPriceSum],
    );

    const handleSetSearchChange = (value) => {
        let searchValue = value == undefined ? '' : value;
        localStorage.setItem('inProgressPaymentsSearch', searchValue);
        setSearchText(searchValue);
    }

    const table = useMaterialReactTable({
        columns,
        data: quotesList,
        rowCount: totalRows,
        initialState: {
            showGlobalFilter: true
        },
        state: {
            globalFilter: searchText,
            pagination
        },
        manualPagination: true,
        manualFiltering: true,
        positionGlobalFilter: 'left',
        muiTopToolbarProps: {
            sx: {
                '& .MuiTextField-root': {
                    minWidth: 450,
                },
            }
        },
        onPaginationChange: setPagination,
        muiPaginationProps: {
            rowsPerPageOptions: [10, 50, 100, 200]
        },
        onGlobalFilterChange: handleSetSearchChange,
        enableFilterMatchHighlighting: false,
        autoResetPageIndex: false,
        muiTablePaperProps: { elevation: 12 },
        defaultColumn: { size: 50 },
        enableSorting: false,
        enableColumnActions: false,
        enableRowActions: true,
        positionActionsColumn: 'last',
        renderRowActions: ({ row, table }) => {
            return <div className={classes.actionButtons}>
                <Tooltip title='Toggle hidden quote'>
                    <IconButton
                        onClick={() => { props.hidePaymentInProgress({ id: row.original.golf_vacation_item_id }) }}
                    >
                        {
                            row.original.hidden ? <VisibilityIcon sx={{ color: 'black' }}/> : <VisibilityOffIcon sx={{ color: 'black' }} />
                        }     
                    </IconButton>
                </Tooltip>
            </div>
        },
        enableDensityToggle: false,
        enableColumnFilters: false,
        enableHiding: false,
        enableFullScreenToggle: false,
        muiTableBodyRowProps: ({ row }) => {
            return {
                className: row.original.hidden ? classes.hiddenRow : '',
            };
        },
    });

    return (
        <div className='inProgressPaymentsSection mainSection'>
            <MaterialReactTable table={table} />
        </div>
    );
}

export default InProgressQuotePayments;